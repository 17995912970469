<template>
  <div>
    <div class="news">
      <div class="news_content">
        <div class="news_header">
          <div class="newstit">{{articletit}}</div>
        </div>
        <div class="new_main" v-html="detail">

        </div>
      </div>
      <div class="news_footer">
        <div class="prevarticle">
          <div class="prevbtn" @click="prevarticle(prev.id, prev.index)"></div>
          <div class="prevtit">{{ prev.title }}</div>
        </div>
        <div class="nextarticle" @click="nextarticle(next.id, next.index)">
          <div class="nexttit"> {{ next.title }}</div>
          <div class="nextbtn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      list: "",
      articleid: "",
      detail: "",
      txt: "",
      articletit: "",
      prev: {
        title: "",

        id: "",
        index: "",
      },
      next: {
        title: "",

        id: "",
        index: "",
      },
    };
  },
  watch: {
    // 如果路由发生变化，再次执行该方法
    $route(to, from) {
      this.getcompany(this.$route.query.id, this.$route.query.index);
    },
  },
  mounted() {
    this.getcompany(this.$route.query.id, this.$route.query.index);
  },
  created() {
    this.articleid = this.$route.query.id;
    // console.log(this.list);
  },
  methods: {
    prevarticle(id, index) {
      // console.log(id, index);

      this.$router.push({
        path: `/sport/plan/plandetail`,
        query: {
          id: id,
          index: index,
          t: Date.now(),
        },
      });
    },
    nextarticle(id, index) {
      // console.log(id, index);

      this.$router.push({
        path: `/sport/plan/plandetail`,
        query: {
          id: id,
          index: index,
          t: Date.now(),
        },
      });
    },

    //获取公司简介
    async getcompany(myid, index) {
      // console.log(myid);
      let params = {
        barId: "9",
      };
      const data = await getbannerdetail(params);
      this.list = data.data.children[1].portalArticleList;
      this.articletit = this.list[index].articleTitle;

      if (index == 0) {
        this.prev.title = this.list[this.list.length - 1].articleTitle;
        this.prev.id = this.list[this.list.length - 1].articleId;
        this.prev.index = this.list.length - 1;

        this.next.title = this.list[1].articleTitle;
        this.next.id = this.list[1].articleId;
        this.next.index = 1;
      } else if (index == this.list.length - 1) {
        this.prev.title = this.list[Number(index) - 1].articleTitle;
        this.prev.id = this.list[Number(index) - 1].articleId;
        this.prev.index = Number(index) - 1;

        this.next.title = this.list[0].articleTitle;
        this.next.id = this.list[0].articleId;
        this.next.index = 0;
      } else {
        this.prev.title = this.list[Number(index) - 1].articleTitle;
        this.prev.id = this.list[Number(index) - 1].articleId;
        this.prev.index = Number(index) - 1;

        this.next.title = this.list[Number(index) + 1].articleTitle;
        this.next.id = this.list[Number(index) + 1].articleId;
        this.next.index = Number(index) + 1;
      }
      // console.log(this.list);
      var address = "http://36.152.65.166:10008";
      var reg = new RegExp("/dev-api");
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].articleContent = this.list[i].articleContent.replace(
          /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
          function (match, p) {
            return `<img src=' ${address + p.replace(reg, "")}'/>`;
          }
        );
      }
      // console.log(this.list);

      this.txt = this.list.find(function (item) {
        return item.articleId == myid;
      });
      this.detail = this.txt.articleContent;
      // console.log(this.detail);
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.news {
  height: 100%;
  .news_content {
    width: 1220px;
    margin: 50px auto;

    .newstit {
      text-align: center;
      font-size: 30px;
      margin-bottom: 30px;
    }
    .newstit1 {
      font-size: 24px;
      margin-bottom: 30px;
    }
    .newsinfo {
      margin-top: 63px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      .infoleft {
        width: 15%;
      }
      .inforight {
        width: 15%;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          margin-right: 10px;
        }
      }
    }
    .new_main {
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-align: justify;
      color: #000000;
      text-indent: 40px;
    }
    .detailimg {
      height: 570px;
      width: 1220px;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #d9d9d9;
    }
  }
  .news_footer {
    border-top: 1px solid #d9d9d9;
    width: 1220px;
    margin: 50px auto;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .prevarticle,
    .nextarticle {
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .prevbtn {
      cursor: pointer;
      width: 26px;
      height: 26px;
      margin-right: 18px;
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .nextbtn {
      cursor: pointer;
      width: 26px;
      height: 26px;
      margin-left: 18px;
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
  }
}
</style>
